<!--
 * @Author: cyy
 * @Date: 2022-01-13 10:03:54
 * @LastEditTime: 2022-12-12 18:54:30
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\installment\newInstallment.vue
-->
<template>
  <div id="newInstallment">
    <el-form ref="form" :model="form" label-position="left" label-width="130px">
      <el-form-item label="免息券期数">
        <div slot="label" class="ml10">免息券期数</div>
        <el-input :value="typeText" disabled></el-input>
      </el-form-item>
      <el-form-item :required="true" label="免息券名称">
        <el-input
          v-model="form.name"
          placeholder="输入免息券名称"
          maxlength="18"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :required="true" label="库存数量">
        <div class="flex-center">
          <el-input-number
            :precision="0"
            :step="1"
            :step-strictly="true"
            v-model="form.stock_num"
            controls-position="right"
            :min="0"
            :max="99999"
          ></el-input-number>
          <span class="ml30 fs12">已领取：{{ form.used_num }}</span>
        </div>
      </el-form-item>
      <el-form-item :required="true" label="领券后">
        <div class="receive_duration_date">
          <el-select
            v-model="receive_duration_date.day"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in 1000"
              :key="index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
          <span class="fs14 ml10 mr20">天</span>
          <el-select
            v-model="receive_duration_date.hour"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in 24"
              :key="index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
          <span class="fs14 ml10 mr20">时</span>
          <el-select
            v-model="receive_duration_date.minute"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in 60"
              :key="index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
          <span class="fs14 ml10 mr20">分</span>
          <el-select
            v-model="receive_duration_date.second"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in 60"
              :key="index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
          <span class="fs14 ml10 mr20">秒 失效</span>
        </div>
      </el-form-item>
      <el-form-item label="领券提示">
        <div slot="label" class="ml10">
          领券提示
          <helpIcon
            style="margin-top: -2px"
            prompt="在学员端领取界面显示，可以填写领取的注意事项和使用"
          ></helpIcon>
        </div>
        <div class="tips">
          <el-input
            type="textarea"
            :rows="3"
            v-model="form.tips"
            maxlength="300"
            show-word-limit
          ></el-input>
          <el-button type="text" size="mini" class="ml10" @click="reset">
            恢复默认
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="公开设置" class="pl10">
        <div class="flex-center">
          <el-checkbox
            v-model="form.is_open"
            true-label="1"
            false-label="2"
          ></el-checkbox>
          <div class="info">
            提示：勾选后，当前免息券下的所有商品的详情页面会展示领取入口，用户可直接领取
          </div>
        </div>
      </el-form-item>
      <el-button
        type="primary"
        class="mr20"
        style="width: 130px"
        size="medium"
        @click="submit(1)"
      >
        保存
      </el-button>
      <el-button
        style="border-color: #0aa29b; color: #0aa29b"
        size="medium"
        @click="submit(2)"
      >
        保存去管理商品
      </el-button>
      <span class="ml20 redcolor fs12">
        提示：保存后下一步即可添加免息适用商品
      </span>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'newInstallment',

  data() {
    return {
      receive_duration_date: {
        day: 0,
        hour: 0,
        minute: 5,
        second: 0,
      },

      form: {
        is_open: '2',
        used_num: 0,
        specs: '',
        name: '',
        stock_num: 5,
        receive_duration: '',
        tips:
          '温馨提示：免息券数量有限，短期内随时会失效，请尽快领取；每人一券仅可领取一次，一券只能用于一件商品。',
      },
    }
  },

  watch: {
    $route: {
      handler() {
        if (this.installment_id) {
          this.$root.$children[0].childPageOptions[
            this.$root.$children[0].childPageOptions.length - 1
          ].name = '编辑'
        }
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.installment_id) {
      this.editInstallment()
    } else {
      this.form.specs = this.selectType
    }
  },

  computed: {
    //  新建时的类型
    selectType() {
      return this.$route.query.selectType || ''
    },
    // 编辑的id
    installment_id() {
      return this.$route.query.installment_id || ''
    },
    typeText() {
      let text = ''
      switch (Number(this.form.specs)) {
        case 1:
          text = '3期免息券'
          break
        case 2:
          text = '6期免息券'
          break
        case 3:
          text = '12期免息券'
          break
      }
      return text
    },
  },

  methods: {
    async editInstallment() {
      try {
        const { data } = await this.$http({
          url: `/Installment/editInstallment?installment_id=${this.installment_id}`,
        })
        let dateTime = Number(data.receive_duration) * 1000

        let days = Math.floor(dateTime / (24 * 3600 * 1000)) // 计算出天数
        let leavel1 = dateTime % (24 * 3600 * 1000) // 计算天数后剩余的时间
        let hours = Math.floor(leavel1 / (3600 * 1000)) // 计算天数后剩余的小时数
        let leavel2 = dateTime % (3600 * 1000) // 计算剩余小时后剩余的毫秒数
        let minutes = Math.floor(leavel2 / (60 * 1000)) // 计算剩余的分钟数

        let leavel3 = dateTime % (60 * 1000) // 计算剩余小时后剩余的毫秒数
        let second = Math.floor(leavel3 / 1000) // 计算剩余的分钟数

        this.receive_duration_date = {
          day: days,
          hour: hours,
          minute: minutes,
          second: second,
        }

        this.form = data
      } catch (e) {
        console.log(e)
      }
    },

    //   保存
    async submit(val) {
      if (!this.form.name.trim()) {
        this.$root.prompt('请输入免息券名称')
        return
      }
      this.form.receive_duration =
        this.receive_duration_date.day * 24 * 60 * 60 +
        this.receive_duration_date.hour * 60 * 60 +
        this.receive_duration_date.minute * 60 +
        this.receive_duration_date.second

      if (this.form.receive_duration <= 0) {
        this.$root.prompt('请选择领券失效时间')
        return
      }

      if (this.installment_id) {
        try {
          await this.$http({
            url: '/Installment/editInstallment',
            data: _.assign({}, this.form, {
              installment_id: this.installment_id,
            }),
          })
          if (val == 2) {
            this.$router.replace(
              `/installment/detailInstallment?installment_id=${this.installment_id}&&fromNewInstallment=2`
            )
          } else {
            this.$router.go(-1)
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          const { data } = await this.$http({
            url: '/Installment/addInstallment',
            data: this.form,
          })
          if (val == 2) {
            this.$router.replace(
              `/installment/detailInstallment?installment_id=${data}&&fromNewInstallment=2`
            )
          } else {
            this.$router.go(-1)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    // 领券提示恢复默认
    reset() {
      this.form.tips =
        '温馨提示：免息券数量有限，短期内随时会失效，请尽快领取；每人一券仅可领取一次，一券只能用于一件商品。'
    },
  },
}
</script>

<style lang="scss" scoped>
#newInstallment {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  ::v-deep .el-input {
    max-width: 618px;
  }
  ::v-deep .el-textarea {
    max-width: 618px;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    color: #333;
  }
  .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .tips {
    .el-button--text {
      transform: translate(10px);
    }
  }
  .receive_duration_date {
    .el-select {
      width: 80px;
    }
  }
  .info {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
